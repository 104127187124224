@import "bootstrap/scss/bootstrap";

@import url("https://fonts.googleapis.com/css2?family=Carrois+Gothic+SC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=League+Gothic&display=swap");

@font-face {
    font-family: "Keep Calm Medium";
    src: url("./fonts/KeepCalm-Medium.ttf");
}

$white-default: #fff;
y
html,
body {
    text-transform: initial;
    overflow: auto !important;
}

.admin-header {
    display: flex;
    justify-content: space-between;
    padding: 1em 3em;
    text-transform: uppercase;

    .header-logo-wrapper {
        display: flex;
        align-items: center;
        gap: 2em;
    }

    .navbar {
        .nav-item a {
            color: #000;
        }
    }
}

.admin-btn {
    color: $white-default;
    border: 2px solid $white-default;
    cursor: pointer;

    &:hover {
        background-color: $white-default;
        color: #000;
    }
}

input,
select,
textarea {
    background-color: transparent;
    border-color: transparent;
    color: $white-default;
    border-bottom: 1px solid $white-default;
    outline: none !important;
    width: 100%;
    /* Full width */
    padding: 8px 0;
    /* Gray border */
    border-radius: 4px;
    /* Rounded borders */
    box-sizing: border-box;
    /* Make sure that padding and width stays in place */
    margin-top: 6px;
    /* Add a top margin */
    margin-bottom: 16px;
    /* Bottom margin */
    resize: vertical;
    /* Allow the user to vertically resize the textarea (not horizontally) */
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $white-default;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $white-default;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $white-default;
}

.login-wrapper {
    font-family: "Carrois Gothic SC","League Gothic", sans-serif;
    text-transform: lowercase;
    background-image: url("/images/login-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: darken;
    height: 100vh;

    .login-container {
        color: $white-default;

        .login-form {
            width: 40%;

            a {
                text-decoration: none;
                color: $white-default;
            }

            /* Style the submit button with a specific background color etc */
        }
    }
}

.upload-wrapper {
    min-height: 100vh;
    background-color: #000;

    .back-row {
        .col {
            display: flex;
            justify-content: flex-end;
            padding: 2em 4em;

            a {
                color: #fff;
                text-decoration: none;
            }
        }
    }

    .admin-header {
        .admin-logo {
            margin-left: 5em;
            width: 25%;
        }

        h4 {
            font-weight: 500;
        }

        background-color: $white-default;
        height: 10vh;
        display: grid;
        grid-template-columns: 1fr 6fr 2fr;
        align-items: center;

        .navbar-nav {
            .nav-item {
                a {
                    color: #000;
                    font-weight: 500;
                    margin-right: 2em;
                }
            }
        }
    }

    .slide-container {
        padding: 2% 15%;
        min-height: 100vh;

        .main-row {
            min-height: 100vh;
            display: grid;
            grid-template-columns: 1fr;
            align-items: flex-start;

            .form-col {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                padding-top: 3em;

                .slide {
                    width: 70%;
                    margin-bottom: 3em;
                    display: grid;
                    grid-template-columns: 1.5fr 1fr;

                    .enter-slide {
                        padding-right: 5em;
                    }

                    .type-options {
                        color: #fff;
                        margin-bottom: 2em;
                    }

                    input[type="text"] {
                        margin-bottom: 2em;
                    }

                    span {
                        text-decoration: none;
                        font-size: 1.2em;
                        color: $white-default;
                    }

                    &:not(:last-child) {
                        display: grid;
                        margin: 1em 0;
                        width: 70%;
                        grid-template-columns: 2fr 1fr 1fr 1fr;
                        grid-gap: 1em;
                    }
                }
            }
        }

        .img-upload-container {
            height: 100%;
            width: 60%;
            color: $white-default;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 1.6em;

            input[type="file"],
            input[type="submit"] {
                display: none;
            }

            label {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 1.3em;
                cursor: pointer;

                i {
                    font-size: 1.5em;
                }

                span {
                    margin-top: -1em;
                }
            }
        }

        .img-preview-container {
            width: 60%;

            .img-preview-wrapper {
                display: flex;
                justify-content: space-between;
            }

            .alert {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 1em 0;
            }

            .image-wrapper {
                border: 2px solid #fff;
                padding: 1em;
                margin: 0;
                margin-top: 3em;
                min-height: 70vh;

                video {
                    width: 100%;
                }
                .youtube-embed {
                    width: 100%;
                    min-height: 250px;
                }

                .col-12 {
                    position: relative;

                    .close-prev-btn {
                        cursor: pointer;
                        position: absolute;
                        top: 5%;
                        right: 10%;
                        border-radius: 4px;
                        z-index: 12;
                        color: #fff;
                        height: auto;
                        width: auto;
                        border: 2px solid white;
                        padding: 1px 4px;
                        font-weight: 500;
                        background-color: rgba(0, 0, 0, 0.3);
                    }
                    .order-button {
                        cursor: pointer;
                        position: absolute;
                        border-radius: 4px;
                        z-index: 12;
                        color: #fff;
                        border: 2px solid white;
                        width: 20px;
                        height: 20px;
                        line-height: 16px;
                        background-color: rgba(0, 0, 0, 0.3);
                        text-align: center;
                        font-size: 15px;

                        &.order-up {
                            top: 10px;
                            left: calc(50% - 10px);
                            transform: rotate(-90deg);
                        }
                        &.order-down {
                            bottom: 60px;
                            left: calc(50% - 10px);
                            transform: rotate(90deg);
                        }
                        &.order-left {
                            top: calc(50% - 10px);
                            left: 10px;
                            transform: rotate(-180deg);
                        }
                        &.order-right {
                            top: calc(50% - 10px);
                            right: 10px;
                        }
                    }

                    figure {
                        img {
                            width: 100%;
                        }

                        input[type="text"] {
                            margin-top: 0.5vmin;
                            color: $white-default;
                            width: 100%;
                        }
                    }
                }
            }

            h2 {
                color: $white-default;
                margin-bottom: 1em;
                text-transform: uppercase;
            }

            h4,
            h6 {
                color: gainsboro;
            }

            #upload-images {
                margin-top: 1em;
                font-size: 0;
                transition: 0.5s;

                &:hover {
                    background-color: $white-default;
                    border: 2px solid $white-default;
                    color: #000;
                }
            }
        }
    }

    .slide-selector-container {
        .main-row {
            .form-col {
                flex-direction: column-reverse;
            }
        }
    }
}
